// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDHDgmA574NVeMWV9SpW-q4QQMjkANuueg',
    authDomain: 'crewdle-sdk-stg.firebaseapp.com',
    databaseURL: 'https://crewdle-sdk-stg-default-rtdb.firebaseio.com',
    projectId: 'crewdle-sdk-stg',
    storageBucket: 'crewdle-sdk-stg.appspot.com',
    messagingSenderId: '519415702791',
    appId: '1:519415702791:web:5dd39042f881cfde3251a2',
    measurementId: 'G-65GE57FYE4',
    functionsRegion: 'northamerica-northeast1',
  },
  segment: {
    key: 'yPZaZl04SGSKlLDw6gqVvY0bqscYvCoj',
  },
  hostname: 'https://dashboard.stg.crewdle.com',
  version: '0.1.6.0',
  featureFlags : {
    organization: true,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
