export enum Resource {
  Mistlet = 'Mistlet',
  PubSub = 'PubSub',
  ObjectStorage = 'ObjectStorage',
  ExternalStorage = 'ExternalStorage',
  KeyValueDatabase = 'KeyValueDatabase',
  VectorDatabase = 'VectorDatabase',
  GraphDatabase = 'GraphDatabase',
  AI = 'AI',
  VPM = 'VPM',
  Model = 'Model',
  Agent = 'Agent',
  Collection = 'Collection',
  Workflow = 'Workflow',
}

export const resourceCollection = {
  [Resource.Mistlet]: 'resources/agent/items',
  [Resource.PubSub]: 'resources/pubsub/items',
  [Resource.ObjectStorage]: 'resources/object-storage/items',
  [Resource.ExternalStorage]: 'resources/external-storage/items',
  [Resource.KeyValueDatabase]: 'resources/key-value-database/items',
  [Resource.VectorDatabase]: 'resources/vector-database/items',
  [Resource.GraphDatabase]: 'resources/graph-database/items',
  [Resource.VPM]: 'resources/cluster/items',
  [Resource.AI]: 'resources/generative-ai/items',
  [Resource.Agent]: 'resources/generative-ai-agent/items',
  [Resource.Model]: 'resources/generative-ai-model/items',
  [Resource.Collection]: 'resources/generative-ai-collection/items',
  [Resource.Workflow]: 'resources/generative-ai-workflow/items',
};
