import { Injectable } from '@angular/core';

import { FirebaseError } from 'firebase/app';
import { arrayUnion, collection, doc, DocumentData, DocumentSnapshot, FieldValue, getDoc, runTransaction, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { v4 as uuidv4 } from 'uuid';

import { Account } from 'src/app/models/account';
import { AWSFulfillment } from 'src/app/models/aws';
import { Organization } from 'src/app/models/organization';

import { AccountService } from 'src/app/services/account/account.service';
import { DataStoreService } from 'src/app/services/data-store/data-store.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { UserService } from 'src/app/services/user/user.service';

interface OrganizationData {
  name: string;
  ownerId: string;
  createdAt: any;
  licenseAccepted: boolean;
  acceptedBy?: string;
  acceptedAt?: FieldValue;
  licenseToken?: string;
  awsFulfillment?: AWSFulfillment;
}

type CreateOrganizationOptions = {
  licenseAccepted?: boolean;
  generateLicenseToken?: boolean;
}

export enum OrganizationErrorCodes {
  OrganizationNotFound = 'organization-not-found',
  OrganizationUnauthorized = 'organization-unauthorized',
  UserNotAuthenticated = 'user',
  FailToCreateOrganization = 'fail-to-create-organization',
}

export class OrganizationError extends Error {
  constructor(readonly code: OrganizationErrorCodes) {
    super(code);
    this.name = 'OrganizationError';
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private loadingOrganizationId?: string;

  constructor(
    private accountService: AccountService,
    private dataStore: DataStoreService,
    private firebase: FirebaseService,
    private user: UserService,
  ) {
     this.dataStore.getState$().subscribe(({ newState }) => {
      if (!newState.authUser) {
        // User is logged out, clear the organization if needed
        return;
      }

      // If we don't have a current account but we have an organziation, we should clear the organization
      if (!newState.currentAccount) {
        if (newState.organization) {
          this.dataStore.updateState({ organization: null });
        }

        return;
      }

      // If the current account has an organization, we should make sure we have the organization in state
      if (newState.currentAccount.organizationId) {
        if (!newState.organization || newState.organization.id !== newState.currentAccount.organizationId) {
          if (this.loadingOrganizationId === newState.currentAccount.organizationId) {
            return;
          }
          this.loadingOrganizationId = newState.currentAccount.organizationId;
          this.getOrganization(newState.currentAccount.organizationId, true);
        }
      } else {
        //  Otherwise we should clear the organization from state
        if (newState.organization) {
          this.dataStore.updateState({ organization: null });
        }
      }
    });
  }

  async getOrganization(organizationId: string, updateCurrentOrganization = false): Promise<Organization> {
    const currentOrganization = this.dataStore.getProperty('organization');
    if (currentOrganization && currentOrganization.id === organizationId) {
      this.loadingOrganizationId = undefined;
      return currentOrganization;
    }

    let organizationDoc: DocumentSnapshot<DocumentData, DocumentData>
    try {
      const currentUser = await this.user.getCurrentUserDocument();
      if (currentUser.awsFulfillment) {
        await setDoc(doc(this.firebase.firestore, 'organizations', organizationId), {
          awsFulfillment: currentUser.awsFulfillment
        }, { merge: true });
        await setDoc(doc(this.firebase.firestore, 'users', currentUser.userId), {
          awsFulfillment: null
        }, { merge: true });
      }

      organizationDoc = await getDoc(doc(this.firebase.firestore, 'organizations', organizationId));
    } catch (error) {
      this.loadingOrganizationId = undefined;
      if (error instanceof FirebaseError) {
        if (error.code === 'permission-denied') {
          this.clearOrganizationAccountFromUser(organizationId);
        }
      }
      console.error('Error fetching organization:', error);
      throw new OrganizationError(OrganizationErrorCodes.OrganizationUnauthorized);
    }

    if (!organizationDoc.exists()) {
      this.loadingOrganizationId = undefined;
      throw new OrganizationError(OrganizationErrorCodes.OrganizationNotFound);
    }

    const { ownerId, name, createdAt, licenseAccepted, licenseToken, awsFulfillment } = organizationDoc.data() as Organization;
    let maxUsers: number | undefined = undefined;

    if (awsFulfillment) {
      const response = await fetch(`https://4nla33eeb3ce5eg4mclh74cs7q0oxtod.lambda-url.us-east-1.on.aws/entitlements?ProductCode=${awsFulfillment.productCode}&CustomerIdentifier=${awsFulfillment.customerIdentifier}`);
      const data = await response.json();
      if (data.Entitlements && data.Entitlements.length === 1) {
        maxUsers = data.Entitlements[0].Value.IntegerValue;
      }
    }

    const organization = {
      id: organizationId,
      name,
      ownerId,
      createdAt,
      licenseAccepted,
      licenseToken,
      awsFulfillment,
      maxUsers,
    }

    if (updateCurrentOrganization) {
      this.dataStore.updateState({ organization });
    }

    this.loadingOrganizationId = undefined;

    return organization;
  }

  async createOrganization(organizationName: string, options: Partial<CreateOrganizationOptions> = {}): Promise<{ organization: Organization, account: Account }> {
    const { licenseAccepted = false, generateLicenseToken = false } = options;
    const ownerId = this.firebase.auth.currentUser?.uid;

    if (!ownerId) {
      throw new OrganizationError(OrganizationErrorCodes.UserNotAuthenticated);
    }

    return this.createOrganizationWithOwner(ownerId, organizationName, licenseAccepted, generateLicenseToken);
  }

  async createOrganizationWithOwner(userId: string, organizationName: string, licenseAccepted = false, generateLicenseToken = false): Promise<{ organization: Organization, account: Account }> {
    const db = this.firebase.firestore;

    // Create a new document reference for the organization
    const organizationRef = doc(collection(db, 'organizations'));

    const organizationData: OrganizationData = {
      name: organizationName,
      ownerId: userId,
      createdAt: serverTimestamp(),
      licenseAccepted,
    };

    if (licenseAccepted) {
      organizationData['acceptedBy'] = userId;
      organizationData['acceptedAt'] = serverTimestamp();
    }

    if (generateLicenseToken) {
      organizationData['licenseToken'] = uuidv4();
    }

    const currentUser = await this.user.getCurrentUserDocument();
    if (currentUser.awsFulfillment) {
      organizationData['awsFulfillment'] = currentUser.awsFulfillment;
    }

    let account: Account;
    try {
      await runTransaction(db, async (transaction) => {
        transaction.set(organizationRef, organizationData);

        const memberRef = doc(organizationRef, 'members', userId);

        transaction.set(memberRef, {
          userId,
          role: 'admin',
          joinedAt: serverTimestamp()
        });

        const userRef = doc(this.firebase.firestore, 'users', userId);
        transaction.update(userRef, {
          organizationIds: arrayUnion(organizationRef.id),
          awsFulfillment: null,
        });
      });

      account = await this.accountService.createAccount(`${organizationName} Default Account`, false, organizationRef.id);
    } catch (e) {
      console.error('Creating organization - Transaction failed:', e);
      throw new OrganizationError(OrganizationErrorCodes.FailToCreateOrganization);
    }

    // WECOULD - Once we remove the location.reload on account switch,
    // We could return the new organization, account, and user (with its new org)
    // So that we update the in memory state without having to fetch the data from Firestore
    const organization = {
      id: organizationRef.id,
      name: organizationName,
      ownerId: userId,
      createdAt: organizationData.createdAt,
      licenseAccepted,
      licenseToken: organizationData.licenseToken,
    };

    return {
      organization,
      account
    };
  }

  async renameOrganization(organizationId: string, newName: string) {
    const organizationRef = doc(this.firebase.firestore, 'organizations', organizationId);
    await updateDoc(organizationRef, {
      name: newName
    });

    const organization = this.dataStore.getProperty('organization');
    if (!organization || organization.id !== organizationId) {
      return this.getOrganization(organizationId, true);
    }

    organization.name = newName;
    this.dataStore.updateState({ organization });

    return organization;
  }

  async linkAccountToOrganization(accountId: string, organizationId: string) {
    const accountRef = this.accountService.getAccountRef(accountId);
    await updateDoc(accountRef, {
      organizationId: organizationId
    });
  }

  async isOrganizationOwner(organizationId: string, userId: string): Promise<boolean> {
    const organization = await this.getOrganization(organizationId);
    if (!organization) {
      return false;
    }
    return organization.ownerId === userId;
  }

  async addOrganizationToUser(userId: string, organizationId: string) {
    const userRef = doc(this.firebase.firestore, 'users', userId);
    return updateDoc(userRef, {
      organizationIds: arrayUnion(organizationId)
    });
  }

  async isLicenseAccepted(organizationId: string): Promise<boolean> {
    const organization = await this.getOrganization(organizationId);
    if (!organization || !organization.licenseAccepted) {
      return false;
    }

    return true;
  }

  async verifyLicenseToken(organizationId: string, token: string): Promise<boolean> {
    try {
      const verifyLicenseCallable =  httpsCallable<{organizationId: string, token: string}, { verified: boolean }>(this.firebase.functions, 'verifyLicenseTokenFromOrganization');
      const result = await verifyLicenseCallable({ organizationId, token });

      return result.data.verified;
    } catch (error) {
      console.error(`Error fetching license token for organization ${organizationId}:`, error);
      throw new Error(`Error fetching license token for organization ${organizationId}`);
    }
  }

  async acceptLicense(organizationId: string, token: string) {
    const acceptLicenseForOrganizationCallable = httpsCallable<{ organizationId: string, token: string }, { success: boolean }>(this.firebase.functions, 'acceptLicenseForOrganization');
    const result = await acceptLicenseForOrganizationCallable({ organizationId, token });

    return result.data.success;
  }

  async acceptLicenseAsAdmin(organizationId: string, acceptedBy: string) {
    const organizationRef = doc(this.firebase.firestore, 'organizations', organizationId);
    return updateDoc(organizationRef, {
      licenseAccepted: true,
      acceptedBy,
      acceptedAt: serverTimestamp()
    });
  }

  async clearOrganizationAccountFromUser(organizationId: string) {
    // We have been removed from the organization and don't have access anymore.
    // If that's our current account, clear the account in state and reset to default account
    const currentAccount = this.dataStore.getProperty('currentAccount');
    if (currentAccount && currentAccount.organizationId === organizationId) {
      const currentUser = this.dataStore.getProperty('currentUser');
      const accountId = currentUser?.defaultAccountId;
      if (accountId) {
        await this.accountService.setCurrentAccount(accountId);
      } else {
        await this.accountService.deleteCurrentAccountInSession();
      }
    }
    window.location.reload();
  }
}
