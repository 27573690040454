import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { onAuthStateChanged } from 'firebase/auth';

import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

import { AnalyticsBrowser } from '@segment/analytics-next';

import { environment } from 'src/environments/environment';

import { FirebaseService } from './services/firebase/firebase.service';
import { ThemeService } from './services/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet, CommonModule],
})
export class AppComponent {
  private analytics: AnalyticsBrowser;

  constructor(
    private firebase: FirebaseService,
    private themeService: ThemeService,
    private router: Router,
  ) {
    this.analytics = AnalyticsBrowser.load({ writeKey: environment.segment.key, cdnURL: 'https://crana.crewdle.com' });
    console.log('App version', environment.version);

    onAuthStateChanged(this.firebase.auth, (authUser) => {
      if (!authUser || authUser.isAnonymous || authUser.email === null) {
        this.themeService.applyTheme(void 0, false, false)
      }
      if (authUser && !authUser.isAnonymous) {
        const createdAt = authUser.metadata.creationTime ?? void 0;
        this.analytics.identify(authUser.uid, {
          email: authUser.email,
          name: authUser.displayName,
          createdAt,
        });
        this.themeService.applyTheme();
      } else {
        this.analytics.reset();
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.analytics.page();
      }
    });
  }
}
