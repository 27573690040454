import { Injectable } from '@angular/core';

import { addDoc, collection, deleteDoc, doc, getDocs, setDoc, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';

import { Resource, resourceCollection } from 'src/app/models/resources';

import { AccountService } from 'src/app/services/account/account.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  collectionMap = {};

  constructor(
    private firebase: FirebaseService,
    private accountService: AccountService
  ) { }

  async get(resource: Resource, accountId?: string): Promise<any[]> {
    if (!accountId) {
      const { id } = await this.accountService.getCurrentAccount();
      accountId = id;
    }
    const collectionPath = resourceCollection[resource];
    const collectionRef = collection(this.firebase.firestore, `developers/${accountId}/${collectionPath}`);
    const snapshot = await getDocs(collectionRef);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  async getById(resource: Resource, docId: string): Promise<any> {
    const { id } = await this.accountService.getCurrentAccount();
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${id}/${collectionPath}/${docId}`);
    const docSnap = await getDoc(docRef);
    return { id: docSnap.id, ...docSnap.data() };
  }

  async add(resource: Resource, data: any): Promise<string> {
    const { id } = await this.accountService.getCurrentAccount();
    const collectionPath = resourceCollection[resource];
    const collectionRef = collection(this.firebase.firestore, `developers/${id}/${collectionPath}`);
    const docRef = await addDoc(collectionRef, { ...data, timestamp: serverTimestamp() });
    return docRef.id;
  }

  async set(resource: Resource, docId: string, data: any): Promise<void> {
    const { id } = await this.accountService.getCurrentAccount();
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${id}/${collectionPath}/${docId}`);
    await setDoc(docRef, data, { merge: true });
  }

  async update(resource: Resource, docId: string, data: any): Promise<void> {
    const { id } = await this.accountService.getCurrentAccount();
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${id}/${collectionPath}/${docId}`);
    await updateDoc(docRef, data);
  }

  async delete(resource: Resource, docId: string): Promise<void> {
    const { id } = await this.accountService.getCurrentAccount();
    const collectionPath = resourceCollection[resource];
    const docRef = doc(this.firebase.firestore, `developers/${id}/${collectionPath}/${docId}`);
    await deleteDoc(docRef);
  }
}
