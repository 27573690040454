import { Injectable } from '@angular/core';
import { Resource } from 'src/app/models/resources';
import { Workflow } from 'src/app/models/resources/workflow';

import { ResourcesService } from 'src/app/services/resources/resources.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(
    private resources: ResourcesService,
  ) {}

  async getDefaultWorkflow(accountId?: string): Promise<Workflow> {
    const workflows = await this.resources.get(Resource.Workflow, accountId) as Workflow[];

    if (!workflows || workflows.length === 0) {
      throw new Error('No workflows found');
    }

    const activeWorkflow = workflows.find(workflow => workflow.deployed && !workflow.deploying);

    if (!activeWorkflow) {
      const workflow = workflows[0];

      return workflow;
    }

    return activeWorkflow;
  }

  async getWorkflows(): Promise<Workflow[]> {
    const workflows = await this.resources.get(Resource.Workflow) as Workflow[];

    return workflows;
  }
}
